<template>
  <div class="home">
    <Pages/>
  </div>
</template>

<script>
// @ is an alias to /src
import Pages from '@/components/Pages.vue'

export default {
  name: 'Home',
  components: {
    Pages
  }
}
</script>
