<template>
  <v-app dark>
    <v-container
    v-if="token === false">
    <v-col>
            <v-col :cols= breakpoints style="margin: 0 auto;">
            <h1 class="text-center mb-8">Anmelden</h1>
            <v-text-field 
            outlined
            label="Email"
            v-model="email"
            ></v-text-field>
            <v-text-field 
            outlined
            type="password"
            label="Passwort"
            v-model="password"
            ></v-text-field>
            <v-btn 
            color="primary"
            x-large 
            class="col-12"
            :loading = loading
            @click="login()"
            >Anmelden</v-btn>
            </v-col>
    </v-col>
    </v-container>
    <div
    v-else
    >
    <v-navigation-drawer
        permanent
        app
        expand-on-hover
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <div v-html="userImg" style="width: 40px; height: 40px"></div>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ UserName }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="Email">{{ Email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
          <v-list-item v-for="link in links" :key="link.name" @click="routerPush(link.link)" link>
            <v-list-item-icon>
              <v-icon>{{link.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{link.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
        <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block @click="routerPush('/sign-out')">
            <v-icon class="pr-5">mdi-lock</v-icon>
            Abmelden
          </v-btn>
        </div>
      </template>
      </v-navigation-drawer>

    <v-app-bar color="primary" style="color: white" app>

      <v-toolbar-title>KjG Admin</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
  export default {
    data: () => ({ 
      loading: false,
      token: false,
      links: [
      {
        name: "Seiten",
        link: "/pages",
        icon: "mdi-file-document-edit"
      },
      {
        name: "Gruppenstunden",
        link: "/gruppenstunden",
        icon: "mdi-account"
      },
      {
        name: "Mitglieder",
        link: "/members",
        icon: "mdi-account-edit"
      },
      {
        name: "Mein Steckbrief",
        link: "/account-data",
        icon: "mdi-pencil"
      },
      {
        name: "Aufgaben",
        link: "/todos",
        icon: "mdi-format-list-checks"
      },
      {
        name: "Lager Anmeldungen",
        link: "/lager",
        icon: "mdi-file-check-outline"
      },
      //{
      //  name: "Kanutour Anmeldungen",
      // link: "/kanutour",
      //  icon: "mdi-file-check-outline"
      //},
      {
        name: "Vergangene Lager",
        link: "/vergangenelager",
        icon: "mdi-home"
      },
      {
        name: "Lager Rezepte",
        link: "/rezepte",
        icon: "mdi-silverware-fork-knife"
      },
      {
        name: "Account",
        link: "/account",
        icon: "mdi-account-cog"
      }
      ],
      tab: null,
      terms: false,
      UserName: "",
        items: [
          'Anmelden', 'Registrieren',
        ],
      password: "",
      email: "",
      Email: "",
      userImg: "",
      name: "",
      LoginUrl: 'https://kjg-api.rezept-zettel.de/api/auth/'
    }),
    computed: {
      /* eslint-disable */ 
      breakpoints () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
      methods: {
        routerPush(link) {
          this.$router.push(link)
        },
        tokenChecker() {
          if(localStorage.getItem('Token') != undefined || '' && localStorage.getItem('RefreshToken') != undefined || '') {
            this.UserName = localStorage.getItem('Username')
            this.Email = localStorage.getItem('Email')
            this.userImg = localStorage.getItem('UserImg')
            return this.token = true
          }
        },
        ValidateForms() {
          if(this.password != "" && this.email != "" || this.password != "" && this.email != "" && this.name != "") {
            return true
          } else {
            return false
          }
        },
        login() {
          this.loading = true
          const Validate = this.ValidateForms()
          if(Validate) {
            axios
            .post(this.LoginUrl + 'login', {
              email: this.email,
              password: this.password,
            })
            .then(Response => {
              this.userImg = Response.data.userImg
              localStorage.setItem('UserImg', Response.data.userImg)
              localStorage.setItem('Token', Response.data.AccessToken)
              localStorage.setItem('RefreshToken', Response.data.RefreshToken)
              localStorage.setItem('Username', Response.data.Name)
              localStorage.setItem('Email', this.email)
              localStorage.setItem('UID', Response.data.ID)
              this.tokenChecker()
              this.loading = false
              Vue.$toast.open({
                  message: 'Erfolgreich angemeldet',
                  type: 'success',
              });
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              Vue.$toast.open({
                  message: 'Benutzername oder Passwort falsch',
                  type: 'error',
              });
            })
          }
        },
        Register() {
          this.loading = true
          const Validate = this.ValidateForms()
          if(Validate) {
            axios
            .post(this.LoginUrl + 'register', {
              email: this.email,
              password: this.password,
              name: this.name
            })
            .then(Response => {
              console.log(Response.data)
              this.login()
              this.loading = false
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
          }
        },
        reset() {
          this.loading = true
          let id = this.$route.params.id
            axios
            .patch('https://kjg-api.rezept-zettel.de/api/auth/reset-password/' + id, {
              password: this.password,
            })
            .then(Response => {
              this.loading = false
              Vue.$toast.open({
                  message: `${Response.data}`,
                  type: 'success',
              });
              this.$router.push('/');
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        }
      },
      created() {
        this.tokenChecker()
      }
  }
</script>

<style>
  .v-toast {
    font-family: "poppins", sans-serif;
  }
  .v-navigation-drawer__content a {
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0px 20px;
    text-decoration: none;
    font-weight: bold;
    color: #555555 !important;
    transition: .3s ease-in;

  }
  .v-navigation-drawer__content a:hover {
    background-color: #efefef;
    transition: .3s ease-in;

  }
</style>