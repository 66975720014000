<template>
  <v-container>
    <router-link class="pages" v-for="link in Pages" :key="link.page" :to="link.link">{{link.page}}</router-link>
  </v-container>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'Pages',
    data() {
      return {
      loading: false,
      Pages: []
      }
    },
    methods: {
      fetchPages() {
        axios
        .get('https://kjg-api.rezept-zettel.de/api/pages')
        .then(response => {
          this.Pages = response.data
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    created() {
      this.fetchPages();
    }
  }
</script>

<style>
.pages {
  display: flex;
  padding: 20px 30px;
  background-color: #efefef;
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
  font-weight: bold;
  color: #616161 !important;
  text-decoration: none;
}
</style>