import Vue from 'vue'
import VueRouter from 'vue-router'
import Pages from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/Members.vue')
  },
  {
    path: '/pages/:id',
    name: 'EditPages',
    component: () => import('../views/EditPages.vue')
  },
  {
    path: '/todos',
    name: 'Todos',
    component: () => import('../views/todo.vue')
  },
  {
    path: '/account-data',
    name: 'Steckbrief',
    component: () => import('../views/steckbrief.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/account.vue')
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: () => import('../views/sign-out.vue')
  },
  {
    path: '/lager',
    name: 'lagerAnmeldung',
    component: () => import('../views/lagerAnmeldung.vue')
  },
  {
    path: '/vergangenelager',
    name: 'alterLager',
    component: () => import('../views/alterLager.vue')
  },
  {
    path: '/lager/:id',
    name: 'alterLagerDetail',
    component: () => import('../views/alterLagerDetail.vue')
  },
  {
    path: '/rezepte',
    name: 'lagerRezepte',
    component: () => import('../views/lagerRezepte.vue')
  },
  {
    path: '/rezept-erstellen',
    name: 'lagerRezepteErstellen',
    component: () => import('../views/lagerRezeptErstellen.vue')
  },
  {
    path: '/rezepte/:id',
    name: 'lagerRezepteDetail',
    component: () => import('../views/lagerRezepteDetail.vue')
  },
  //{
  //  path: '/kanutour',
  //  name: 'Kanutour',
  //  component: () => import('../views/Kanutouranmeldung.vue')
  //},
  {
    path: '/detail/:id',
    name: 'TodoDetail',
    component: () => import('../views/todoDetail.vue')
  },
  {
    path: '/reset-password/:id',
    name: 'ResetPassword',
    component: () => import('../views/reset-password.vue')
  },
  {
    path: '/gruppenstunden',
    name: 'Gruppenstunden',
    component: () => import('../views/Gruppenstunden.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
